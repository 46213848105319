import { HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { ProjectDateRangeRequest } from 'src/app/common/components/filters/date-range/date-range.model'

import { ListRequest } from '~common/types/api'
import { JsonResponse, PaginatedData } from '~core/models/json-response.model'
import { ApiService } from '~core/services'

import { Insight, InsightsOverview, InsightsRequest, Post, PostRequest, PostsRequest } from '../models'

@Injectable({
  providedIn: 'root',
})
export class LinkedinOrganizationApiService {
  #defaultVersion = 'v1.1'
  private _prefix = '/{version}/projects/{projectId}/linkedin-organization'

  constructor(private apiService: ApiService) {}

  insights({ projectId, fields, ...range }: InsightsRequest): Observable<JsonResponse<Insight[]>> {
    const params = new HttpParams({ fromObject: { ...range, fields: fields.join(',') } })
    return this.apiService.get(`${this.prefix(projectId)}/insights`, params)
  }

  insightsOverview({ projectId, ...range }: ProjectDateRangeRequest): Observable<JsonResponse<InsightsOverview>> {
    const params = new HttpParams({ fromObject: { ...range } })
    return this.apiService.get(`${this.prefix(projectId)}/overview`, params)
  }

  post({ projectId, postId }: PostRequest): Observable<JsonResponse<Post>> {
    return this.apiService.get(`${this.prefix(projectId)}/posts/${postId}`)
  }

  posts(projectId: string, query: ListRequest): Observable<PaginatedData<Post>> {
    const params = new HttpParams({ fromObject: { ...query } })
    return this.apiService.get(`${this.prefix(projectId, 'v1.2')}/posts`, params)
  }

  private prefix(projectId: string, version: string = this.#defaultVersion): string {
    return this._prefix.replace('{projectId}', projectId).replace('{version}', version)
  }
}
